import React from 'react'
import Nav from "../component/nav"
import bg2 from "./../images/bg_2.png";
import "./../styles/global.css"
import { Link } from 'gatsby';
import FadeIn from 'react-fade-in';

const about = () => {
    return (
        <div class="container mx-auto">
            <Nav />
            <FadeIn>
                <div class="space-y-4 laptop:flex">
                    <img src={bg2} alt="a pic" class="w-1/2 my-3 mx-auto laptop:order-last laptop:py-10"></img>
                    <div class="block laptop:w-1/2 laptop:py-10">
                        <h1 class="text text-4xl font-bold px-10 py-2 text-center laptop:text-5xl laptop:text-left">About Me</h1>
                        <p class="text text-md leading-8 mx-auto py-3 w-10/12 text-justify laptop:text-left laptop:px-10 laptop:py-4 laptop:mx-0">
                            My name is Alex Kem. I'm a computer science major at California State University of Long Beach. Currently, I'm a junior and 
                            I will be expected to graduate around Spring 2024. 
                        </p> 
                        <p class="text text-md leading-8 mx-auto py-3 w-10/12 text-justify laptop:text-left laptop:px-10 laptop:mx-0">During my time as a undergrad, I've taken CS courses such as
                        Data Structure & Algorithms, Object Oriented Programming, Discrete Mathematics, and Advance C++.
                        </p>
                        <p class="text text-md leading-8 mx-auto py-3 w-10/12 text-justify laptop:text-left laptop:px-10 laptop:mx-0">On my free time, I enjoy
                        doing web and mobile development and I have learned and worked with React, Flutter, and Firebase. 
                        </p>
                        <p class="text text-md leading-8 mx-auto py-3 w-10/12 text-justify laptop:text-left laptop:px-10 laptop:mx-0">In the future, I plan on graduating Cal State University
                        of Long Beach with a BS in Computer Science and work full-time as a software engineer. 
                        </p>
                    </div>
                </div>
                <div>
                    <h1 class="text text-3xl font-bold mx-auto pt-36 text-center laptop:text-5xl laptop:text-left laptop:px-10 laptop:mx-0">i'm experienced in...</h1>
                    <div class="px-3 justify-items-center grid grid-cols-2 gap-7 pt-10 laptop:grid-cols-5 laptop:gap-5 laptop:px-5 laptop:justify-items-center">
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" />
                        <img class="w-1/2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg" />
                    </div>
                </div>
                <div>
                    <h1 class="text text-3xl font-bold px-10 pt-24 laptop:text-2xl text-center">time to see my projects</h1>
                    <div class="px-10 py-10 text-center laptop:pt-5">
                        <Link to="/projects">
                            <button class="btn">
                                View Projects
                            </button>
                        </Link>
                    </div>
                </div>
            </FadeIn>
        </div>
    )
}

export default about
